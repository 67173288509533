import React from "react"
import { Link } from "gatsby"
//import { Card } from "react-bootstrap"

const PostsListCard = ({ frontmatter, fields, excerpt }) => {
  const title = frontmatter.title || fields.slug

  return (
      <div>
          <h2>
              {title}
          </h2>
          <p>
          <div 
            dangerouslySetInnerHTML={{
                __html: frontmatter.description || excerpt,
            }}
          />
          <Link to={`/${fields.slug}/`}>
            Read more
          </Link>
          </p>
        <hr/>
      </div>
  )
}

export default PostsListCard